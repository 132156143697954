export async function supabaseUpdatePassword(new_pwd: string) {
  // https://supabase.com/docs/reference/javascript/auth-updateuser

  const supabase = useSupabaseClient()
  const { error } = await supabase.auth.updateUser({ password: new_pwd })

  return error
}

export async function supabaseSendResetPasswordLink(email: string) {
  // https://supabase.com/docs/reference/javascript/auth-resetpasswordforemail
  const supabase = useSupabaseClient()
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${window.location.origin}/update-password`,
  })
  return error
}
